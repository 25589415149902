<!-- Home Parallax -->
<div class="home-parallax home-content parallax-top-bg dark-overlay" style="background:url(assets/images/home-bg.jpg) no-repeat fixed;">
    <div class="header-inner">
      <div class="header-table">
        <div class="col-md-offset-2 col-md-8">
          <h2>404 Not Found!</h2>
          <p>The page your looking for can't be found.</p>
          <a routerLink="" class="default-button white-bg mt-30">Back home</a>
        </div>
      </div>
    </div>
  </div><!-- /Home Parallax -->
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <app-footer></app-footer>
  </div>
  <!-- /Page Wrapper -->
  