import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { Dry } from "../models/dry";

@Injectable({
  providedIn: 'root'
})
export class DryService {
  dryCollection: AngularFirestoreCollection<Dry>;
  drys: Observable<Dry[]>;

  constructor(firestore: AngularFirestore) {
    this.drys = firestore.collection('drys', ref => ref.orderBy('order')).valueChanges();
   }

  getItems(){
    return this.drys;
  }
}
