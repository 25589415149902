<!-- Custom Page Header -->
<section class="custom-header fill dark-overlay" style="background:url(assets/images/about-bg.jpg) no-repeat fixed;">
	<div class="header-inner">
		<div class="header-table">
			<h2>About Us</h2>
			<p><a class="brdcrm-home" routerLink="">Home</a> &mdash; About Us</p>
		</div>
	</div>
</section><!-- /Custom Page Header -->
<div class="page-wrapper">
	<!-- About Area -->
	<section class="about-area section-padding">
		<div class="container">
			<div class="row">
				<div class="col-md-4 col-sm-12 col-xs-12">
					<div class="about-content">
						<div class="section-title">
							<h2>Excell facility</h2>
						</div>
						<p>Excell’s manufacturing plant is located just outside of Scranton, in the town of Pittston, PA. Our facility rests on a three acre tract of land. Some of the features the facility has include:</p>
						<ul class="facility-list">
							<li>10,000 ft<sup>2</sup> facility</li>
							<li>Contains 4 full size bays, and 1 loading dock</li>
							<li>In-house machining, fabrication, welding, and electrical departments</li>
							<li>In-house parts room</li>
							<li>State of the Art equipment testing stations</li>
							<li>UL508A Certified Panel Shop</li>
						</ul>
					</div>
				</div>
				<div class="col-md-8 col-sm-12 col-xs-12">
					<div class="about-content">
						<div class="section-title">
							<h2>Excell history</h2>
						</div>
						<p>Founded in 1993, Excell Feeders revolutionized the waste water treatment world. Realizing the need for a more effective and hassle-free polymer feeder, Excell set out to develop just that. The result was a feeder that was motorless, self-cleaning and above all, easy to operate.</p>
						<p>The patented polymer activation apparatus eliminates many of the complications experienced by other motor operated systems. This combined with the self cleaning sight tube and user-friendly interface make Excell the #1 choice for polymer feeders!</p>
						<p>Excell’s feeders are manufactured in Pittston, PA. Feeders are shipped worldwide, and there are currently over 2000 feeders being operated on over 4 continents. Both standard and custom units are available upon request, and are capable of accommodating both ultra-low as well as high capacity polymer flows.</p>
						<p>Please feel free to contact us with any questions or to receive a proposal.</p>
					</div>
				</div>
			</div>
		</div>
	</section><!-- /About Area -->
	<!-- Achive area -->
	<section class="achive-area section-padding">
		<div class="container">
			<div class="row">
				<div class="section-title">
					<h2>Excell achievements</h2>
				</div>
			</div>
			<div class="row">
				<div class="col-md-3 col-sm-6 col-xs-12">
					<div class="single-achive">
						<span class="pe-7s-server"></span>
						<h3 class="counter">2300</h3>
						<p>Project Done</p>
					</div>
				</div>
				<div class="col-md-3 col-sm-6 col-xs-12">
					<div class="single-achive">
						<span class="pe-7s-smile"></span>
						<h3 class="counter">440</h3>
						<p>Happy Clients</p>
					</div>
				</div>
				<div class="col-md-3 col-sm-6 col-xs-12">
					<div class="single-achive">
						<span class="pe-7s-hourglass"></span>
						<h3 class="counter">25</h3>
						<p>Years in Business</p>
					</div>
				</div>
				<div class="col-md-3 col-sm-6 col-xs-12">
					<div class="single-achive">
						<span class="pe-7s-cup"></span>
						<h3 class="counter">5.5 B</h3>
						<p>Gallons of Solution</p>
					</div>
				</div>
			</div>
		</div>
	</section><!-- /Achive area -->

	<!-- Team Area -->
	<!-- <section class="team-area section-padding">
	<div class="container">
	<div class="row">
	<div class="section-title">
	<h2>Excell Members</h2>
	<p>Meet our team</p>
</div>
</div>
<div class="row">
<div class="col-md-4 col-sm-6 col-xs-12">
<div class="single-member">
<img class="img-responsive" src="assets/images/team/people-1.jpg" alt="" />
<h5>Alfonso M. Misuraca</h5>
<p>Chief Executive Officer</p>
<a href="mailto:amisuraca@excellfeeders.com"><i class="fa fa-envelope"></i></a>
</div>
</div>
<div class="col-md-4 col-sm-6 col-xs-12">
<div class="single-member">
<img class="img-responsive" src="assets/images/team/people-2.jpg" alt="" />
<h5>Linda Misuraca</h5>
<p>President</p>
<a href="mailto:linda.porowski@excellfeeders.com"><i class="fa fa-envelope"></i></a>
</div>
</div>
<div class="col-md-4 col-sm-6 col-xs-12">
<div class="single-member">
<img class="img-responsive" src="assets/images/team/people-3.jpg" alt="" />
<div class="single-member-content">
<h5>Robert Dovin</h5>
<p>Shop Manager</p>
<a href="mailto:robdovin@excellfeeders.com"><i class="fa fa-envelope"></i></a>
</div>
</div>
</div>
</div>
</div>
</section><!-- /Team Area --> -->

</div>
<app-footer></app-footer>
