import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";

import { environment } from "../environments/environment";
export const firebaseConfig = environment.firebaseConfig;

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { LiquidComponent } from './liquid/liquid.component';
import { DryComponent } from './dry/dry.component';
import { IcpComponent } from './icp/icp.component';

import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule, AngularFirestoreDocument } from "@angular/fire/firestore";

import { AgmCoreModule } from "@agm/core";

import { ContactService } from "./services/contact.service";
import { DryService } from "./services/dry.service";
import { LiquidService } from "./services/liquid.service";


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    HeaderComponent,
    AboutComponent,
    ContactComponent,
    NotfoundComponent,
    TermsComponent,
    PrivacyComponent,
    LiquidComponent,
    DryComponent,
    IcpComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDDKOJr3IAeTeaLTiOxs_RFALFUQGRBimk'
    })
  ],
  providers: [ContactService, LiquidService, DryService],
  bootstrap: [AppComponent]
})
export class AppModule { }
