<!-- Custom Page Header -->
<div class="custom-header fill dark-overlay" style="background:url(assets/images/dry-bg.jpg) no-repeat fixed;">
    <div class="header-inner">
      <div class="header-table">
        <h2>Dry Systems</h2>
        <p><a class="brdcrm-home" routerLink="">Home</a> &mdash; Dry Systems</p>
      </div>
    </div>
  </div><!-- /Custom Page Header -->
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <!-- Description Section -->
    <section class="section-padding">
      <div class="container">
        <div class="row">
          <div class="section-title">
            <h2>Wetting Chamber</h2>
            <p>Advanced Dry Polymer Wetting</p>
          </div>
        </div>
        <div class="row">
          <p>Our dry polymer feed system is engineered to provide a reliable means of feeding dry polymers. A multi-step process efficiently disperses and hydrates the polymer into a homogeneous solution. The initial step wets the polymer with multiple sprays. The solution is then suctioned by a high energy eductor which conveys it to the aging tank. The solution is then gently agitated until fully aged. The system is fully modular. It is available complete with tanks and solution pumps. The tanks can be configured over under, side-by-side, or sequentially. Our system can also be adapted to utilize existing tanks.</p>
        </div>
      </div>
    </section><!-- /Description Section -->
    <!-- Sizing Section -->
    <section class="sizing-area section-padding">
      <div class="container">
        <div class="row">
          <div class="section-title">
            <h2>Flow Rates</h2>
            <p></p>
          </div>
        </div>
        <div class="row">
          <div *ngFor="let system of drys">
            <h4>{{system.description}}</h4>
            <a href="{{system.brochure}}">Download Brochure</a>
            <div class="container mt-60">
              <div class="row">
                <div class="col-md-2">
                  <strong>Model Number</strong>
                </div>
                <div class="col-md-2">
                  <strong>Fill Rate</strong>
                </div>
                <div class="col-md-4">
                  <strong>Polymer Capacity</strong>
                </div>
                <div class="col-md-4">
                  <strong>Solution Capacity (30 minutes aging)</strong>
                </div>
              </div>
              <hr>
              <div *ngFor="let model of system.models">
                <div class="row">
                  <div class="col-md-2">
                    <p>{{model.modelNumber}}</p>
                  </div>
                  <div class="col-md-2">
                    <p>{{model.fillRate}}</p>
                  </div>
                  <div class="col-md-4">
                    <p>{{model.polymerCapacity}}</p>
                  </div>
                  <div class="col-md-4">
                    <p>{{model.solutionCapacity}}</p>
                  </div>
                </div>
                <hr>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section><!-- /Sizing Section -->
    <!-- Options Section -->
    <section class="faqs-area section-padding">
      <div class="container">
        <div class="row">
          <div class="section-title">
            <h2>Features & Options</h2>
            <!-- <p>Customizable to Meet your Needs</p> -->
          </div>
        </div>
        <div class="row">
          <h4>Standard Features</h4>
          <ul class="features-list">
            <li>Hopper has a standard capacity of 3.8 cu. ft. (107L).</li>
            <li>Vibrator prevents polymer from bridging.</li>
            <li>Sensors ensure reliable operation.</li>
            <li>Air heater provides low humidity air to the discharge.</li>
            <li>Easy polymer feed rate calibration.</li>
            <li>Reliable PLC control logic.</li>
            <li>User friendly operator interface.</li>
            <li>Dilution capacities from 5 to 80 GPM.</li>
            <li>Stainless steel frame. The frame is floor mounted.</li>
          </ul>
          <h4>Available Options</h4>
          <ul class="features-list">
            <li>Hopper capacities.</li>
            <li>Dilution water flow rates above 80 GPM.</li>
            <li>Variety of bulk bag handling systems.</li>
            <li>Tanks of various sizes and arrangements.</li>
            <li>Solution pumps.</li>
            <li>Voltages other than 120 VAC are available.</li>
            <li>UL508A control panel.</li>
            <li>Custom designed to meet your needs.</li>
          </ul>
        </div>
      </div>
    </section><!-- /Options Section -->
    <!-- Dimensions Section -->
    <section class="sizing-area section-padding">
      <div class="container">
        <div class="row">
          <div class="section-title">
            <h2>Dimensions</h2>
            <p>General Arrangement</p>
          </div>
        </div>
        <div class="row">
          <h4>Auger Feeder</h4>
          <h5>No Tanks</h5>
          <img src="assets/images/8C-300.png">
        </div>
        <div class="row">
          <small>* May not be drawn to scale</small>
        </div>
      </div>
    </section><!-- /Dimensions Section -->
    <app-footer></app-footer>
  </div><!-- /Page Wrapper -->
  