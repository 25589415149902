import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { Liquid } from "../models/liquid";

@Injectable({
  providedIn: 'root'
})
export class LiquidService {
  liquidCollection: AngularFirestoreCollection<Liquid>;
  liquids: Observable<Liquid[]>;

  constructor(firestore: AngularFirestore) {
    this.liquids = firestore.collection('liquids').valueChanges();
   }

  getItems(){
    return this.liquids;
  }
}
