<!-- Custom Page Header -->
<div class="custom-header fill dark-overlay" style="background:url(assets/images/electrical-bg.jpg) no-repeat fixed;">
    <div class="header-inner">
      <div class="header-table">
        <h2>Industrial Control Panels</h2>
        <p><a class="brdcrm-home" routerLink="">Home</a> &mdash; Industrial Control Panels</p>
      </div>
    </div>
  </div><!-- /Custom Page Header -->
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <!-- Description Section -->
    <section class="section-padding">
      <div class="container">
        <div class="row">
          <div class="section-title">
            <h2>Industrial Control Panels</h2>
            <p>UL508A Certified Panel Shop</p>
          </div>
        </div>
        <div class="row">
          <p>Excell is recognized as a certified UL508A panel shop. Certification allows panel shop to independently design and produce UL ICP and apply a UL label. Each label certifies adherence to a known safety standard and is traceable to the panel shop that produced it.
          This an industrial control panel directive, which is sought by electrical inspectors and is certified through Underwriter Laboratories, an organization that has been around for more than 100 years.
          Whether your panel project is a one-off custom design, or a standard model with quantities in the hundreds, Excell can handle it.</p>
        </div>
      </div>
    </section><!-- /Description Section -->
    <!-- Services Section -->
    <section class="sizing-area section-padding">
      <div class="container">
        <div class="row">
          <div class="section-title">
            <h2>Our Panel Services</h2>
            <p></p>
          </div>
        </div>
        <div class="row">
          <h4>System Integration, Design, and Construction Services</h4>
          <ul class="features-list">
            <li>Programmable Logic Controller (PLC) Programming<br/><em>Ladder Logic, Function Block Diagram, Sequential Function Charts, and Structured Text</em></li>
            <li>Human-Machine Interface (HMI) Programming</li>
            <li>Field Start-up/commisioniong services</li>
            <li>Cost estimating</li>
            <li>Control Systems Networking Design</li>
            <li>Submittal/Shop drawings</li>
            <li>Material procuremnet/equipment purchasing management</li>
            <li>Instrumentaion services, including process control loop drawings, calibration reports & loop testing</li>
          </ul>
        </div>
        <div class="row">
          <h4>Certifications for both US and Canada</h4>
          <ul class="features-list">
            <li>UL 508A Industrial Control Panel: File # <a href="https://iq.ulprospector.com/en/profile?e=73067">NITW.E475934</a>, <a href="https://iq.ulprospector.com/en/profile?e=78152">NITW7.E475934</a></li>
          </ul>
        </div>
      </div>
    </section><!-- /Services Section -->
    <!-- Related works
    <section class="related-work section-padding">
      <div class="container">
        <div class="row">
          <div class="section-title">
            <h2>Related Works</h2>
            <p>See more like this</p>
          </div>
        </div>
        <div class="related-work-item owl-carousel">
          <div class="portfolio hover-portfolio">
            <div class="portfolio-content">
              <img src="images/portfolio/port1.jpg" alt="" />
              <div class="portfolio-view">
                <a class="img-link" href="single-portfolio-02.html">
                  <i class="pe-7s-link"></i>
                  <p>Lorem Ipsum</p>
                </a>
              </div>
            </div>
          </div>
          <div class="portfolio hover-portfolio">
            <div class="portfolio-content">
              <img src="images/portfolio/port2.jpg" alt="" />
              <div class="portfolio-view">
                <a class="pop-image" href="images/portfolio/port2.jpg">
                  <i class="pe-7s-camera"></i>
                  <p>Lorem Ipsum</p>
                </a>
              </div>
            </div>
          </div>
          <div class="portfolio hover-portfolio">
            <div class="portfolio-content">
              <img src="images/portfolio/port3.jpg" alt="" />
              <div class="portfolio-view">
                <a class="pop-video" href="https://www.youtube.com/watch?v=rXcp6s0VjZk">
                  <i class="pe-7s-play"></i>
                  <p>Lorem Ipsum</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    /Related works -->			
    <!-- Photos Section -->
    <section class="section-padding">
      <div class="container">
        <div class="row">
          <div class="section-title">
            <h2>Photos</h2>
            <p>Previous Work</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="image-wrapper">
              <div class="use-cover-background square" style="background-image:url(assets/images/icp-1.JPG)"></div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="use-cover-background square" style="background-image:url(assets/images/icp-3.JPG)"></div>
          </div>
          <div class="col-md-4">
            <div class="use-cover-background square" style="background-image:url(assets/images/icp-4.JPG)"></div>
          </div>
        </div>
      </div>
    </section><!-- /Photos Section -->
    <app-footer></app-footer>
  </div><!-- /Page Wrapper -->
  
