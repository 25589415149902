import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LiquidService } from '../services/liquid.service';
import { Liquid } from '../models/liquid';

@Component({
  selector: 'app-liquid',
  templateUrl: './liquid.component.html',
  styleUrls: ['./liquid.component.css']
})
export class LiquidComponent implements OnInit {
  subscription: Subscription;
  liquids: Liquid[];

  constructor(private liquidService: LiquidService) {
    //Subcribe to the service
    this.subscription = this.liquidService.getItems().subscribe(liquids => {
      this.liquids = liquids;
    })
   }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    //Unsubscribe from the service on destroy
    this.subscription.unsubscribe();
  }

}
