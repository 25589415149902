import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { Contact } from "../models/contact";

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  contactCollection: AngularFirestoreCollection<Contact>;
  contact: Observable<Contact[]>;

  constructor(firestore: AngularFirestore) {
    this.contactCollection = firestore.collection('contacts')
  }
  addContact(contact: Contact){
    this.contactCollection.add(contact);
  }
}
