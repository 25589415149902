<!-- Custom Page Header -->
<div class="custom-header fill dark-overlay" style="background:url(assets/images/home-bg.jpg) no-repeat fixed;">
    <div class="header-inner">
      <div class="header-table">
        <h2>Terms and Conditions</h2>
        <p><a class="brdcrm-home" routerLink="">Home</a> &mdash; Terms and Conditions</p>
      </div>
    </div>
  </div><!-- /Custom Page Header -->
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <!-- Description Section -->
    <section class="section-padding">
      <div class="container">
        <div class="row">
          <h2>Binding Contact</h2>
          <p>Unless otherwise noted in Excell Feeders’ Proposal, the Proposal shall lapse automatically upon the expiration of ninety (90) day period after the date of its submission unless it has been previously accepted by Purchaser or revoked in writing by Excell Feeders. The contract incorporating these Terms and Conditions does not become a binding contract until Excell Feeders receives the Purchaser’s unqualified acceptance of the Proposal or Excell Feeders confirms the Purchaser’s order in writing. These Terms and Conditions are the only terms and conditions on which Excell Feeders contracts for the supply of Equipment and they are incorporated in all contracts entered into by Excell Feeders. Any other terms and conditions are hereby specifically rejected and therefore excluded.</p>
          <br/>
          <h2>Plans, Drawings and Illustrations</h2>
          <p>Proposal pages, catalogue illustrations and preliminary drawings are submitted only to show the general style, arrangement, approximate dimensions, and weight of equipment. Excell Feeders reserves the right to make such changes of design, construction or arrangement as it deems necessary to achieve the specifications contained herein.</p>
          <br/>
          <h2>Propriety and Confidential Information</h2>
          <p>This Proposal and all drawings, notebooks, operating data, specifications, and other information, data, and material (whether orally disclosed, printed, handwritten, typed, numerically or computer generated, computer stored, or otherwise) furnish to Purchaser by either Excell Feeders or any of its subcontractors or sub-suppliers shall remain the proprietary and confidential property of Excell Feeders or the subcontractor or sub-supplier, respectively, and shall be used by Purchaser only with respect to the work covered by the Contract and shall not be used by Purchaser in connection with any other project. Such proprietary information and data shall not be shown or made available to any third party without Excell Feeders’ prior written consent.
            Neither Purchaser itself shall, nor shall Purchaser permit any third party to, reverse engineer, measure or otherwise technically examine or test Excell Feeders’ Equipment without Excell Feeders’ prior written consent. Any such proprietary and confidential information which Purchaser determines must be disclosed to its employees shall only be disclosed to them on a need-to-know basis for the operation, maintenance, and repair of the Equipment provided under the Contract, Intellectual  property or patent rights which may be obtained on the basis of the information given or made available to Purchaser under the Contract or with respect to Excell Feeders’ Equipment shall remain the exclusive property of Excell Feeders or its subcontractor and/or sub-supplier, respectively.</p>
            <br/>
            <h2>Compliance with Laws</h2>
            <p>Excell Feeders shall use reasonable endeavors to ensure that the Equipment complies in technical respect with the agreed standards and in all other respects with applicable laws, rules, regulations, codes, and standards of all federal, state, local, and municipal governmental agencies having applicable regulatory jurisdiction, as such laws, rules, regulations, codes and standards are in effect on the date of the contract, provide that: (i) the Purchaser will include in its specifications or will bring to the attention of Excell Feeders in writing any state, local or municipal laws, rules, regulations codes or standards which are different from those imposed by the federal government agencies and authorities; (ii) if any such federal, state, local or municipal laws, rules, regulations, codes or standards, are changed, or if new laws , regulations, codes, or standards or interpretations thereof are enacted or adopted subsequent to the date of the contact, which require a change in Excell Feeders’ equipment or work, and equitable adjustment shall be made to the contract price, delivery schedule and payment terms; and (iii) Excell Feeders does not guarantee any compliance with, nor will Excell Feeders incur any liability for failure of the equipment or work to comply with, any federal, state, or local pollution control, effluent or utility control laws, rules, regulations, codes or standards.</p>
            <br/>
            <h2>Price and Payment</h2>
            <p>The purchase price shall be paid in accordance with the Proposal. Any right to retain due payments or to set-off counterclaims shall be excluded unless any such claim or counterclaim of the Purchaser is undisputed by Excell Feeders or has been determined by a final judgment of the competent court or arbitration panel.</p>
            <p>Any tax or other governmental charge now or thereafter levied upon the production, sale, use or shipment of equipment ordered or sold will be charged to and paid for by the Purchaser. Such taxes are not covered in Excell Feeders’ price(s) unless expressly so stated on Excell Feeders’ Proposal.</p>
            <p>If the Purchaser fails to pay by the stipulated date, Excell Feeders shall be entitled to interest from the day on which payment was due. The rate of interest shall be one and one-half percent (1 ½ %) per month until the payment is made in full. If Excell Feeders is required to expend costs and expenses in collecting any payments, Purchaser shall reimburse Excell Feeders for such costs of collection (including reasonable attorney’s fees).</p>
            <p>In case of late payment Excell Feeders may suspend performance of the Contract until it receives payment. If the Purchaser has not paid the amount due within three months of the due date, Excell Feeders shall be entitled to terminate the contact by notice in writing to the Purchaser and to claim compensation for the losses and damages it has incurred.</p>
            <p>Deposits and pre-payments are not refundable, unless expressly so stated in Excell Feeder’s proposal.</p>
            <br/>
            <h2>Force Majeure</h2>
            <p>Excell Feeders shall not be liable for delay or loss or damage of any kind resulting from: (i) Purchaser failing to supply any necessary technical data, as required; (ii) Purchaser failing to supply the apparatus, materials and services required; (iii) any changed in designs or specifications made subsequent to acceptance of this Proposal; (iv) failure of suppliers to furnish purchased material or auxiliary equipment within scheduled dates provided that the purchased materials or auxiliary equipment was properly ordered and appropriately expedited; (v) by any other reason beyond Excell Feeders’ control; or (vi) any delay caused by late payments by Purchaser.</p>
            <p>Excell Feeders shall attempt to overcome but shall not be liable for any loss or damage from delay in delivery of any Equipment or completion of any work as a result of causes of any kind beyond the reasonable control of Excell Feeders, including, but not limited to, strikes or other labor difficulties, war, riots, changes in laws and regulations and other acts of governmental authorities, inclement weather, fire, flood or unavoidable casualties, or any delays in transportation of materials, or inability to obtain timely delivery of materials from suppliers where such transportation or delivery has been properly procured and appropriately expedited. In the event of any such delay, Excell Feeders will notify the Purchaser within a reasonable time after Excell Feeders becomes aware of such cause of delay and it is agreed that the time for delivery or completion shall be extended for a period of time at least equal to the time lost by reason of the delay.</p>
            <br/>
            <h2>Material and Workmanship Warranty</h2>
            <p>Excell Feeders warrants to the Purchaser that the Equipment purchased from Excell Feeders is free from defects in material and workmanship. The warranty period shall be twelve (12) months from the date of Purchaser’s initial operation using the Equipment but not more than eighteen (18) months from the date of delivery of the Equipment. Any warranty given by Excell Feeders shall be subject to the following: (i) the Equipment is installed in accordance with Excell Feeders’ specification and instructions and is used and maintained normally and properly in accordance with Excell Feeders’ instructions as to maintenance and operation, as set forth in written operation and maintenance manuals and instruction sheets furnished by Excell Feeders; (ii) the Equipment has not been changed without the prior written approval of Excell Feeders; (iii) Purchaser gives prompt written notice to Excell Feeders before the end of the warranty period specifying all alleged defects in the Equipment purchased;  and (iv) Purchaser preserves and turns over to Excell Feeders and permits reasonable inspection by Excell Feeders of all allegedly defective Equipment, parts or items and access to the Equipment to observe its startup, operation, and maintenance.</p>
            <p>This warranty shall not cover (i) any equipment furnished by Purchaser or any third party; (ii) any defects arising from corrosion, abrasion, use of unsuitable lubricants, freezing or other operation outside of prescribed temperature ranges, or negligent attendance or faulty operation; (iii) ordinary wear and tear (e.g., gaskets); or (iv) any defects caused by errors on the part of the Purchaser in not providing a suitable place in which the Equipment is to be located, adequate foundation works, or adequate protection against influences within or outside the place where the Equipment is to be located which may affect the Equipment or its operation (improper storage); or (v) the performance of any equipment sold by Excell Feeders under conditions varying materially from those under which such equipment is usually tested under existing industry standards. Notwithstanding the warranty set forth above, Excell Feeders shall not warrant any equipment, where the vendor of such equipment (other than Excell Feeders) is specified by Purchaser, for a period longer than warranted by the vendor.</p>
            <p>Excell Feeders’ liability is limited to the shipment of replacements for the defective parts on the equipment supplied to the purchaser. Excell Feeders is not liable for the removal of the defective parts and the installation of the replacement parts. The defective parts shall be returned to Excell within one week after the defective part is replaced. Failure to return the defective part voids the warranty on the part. Warranty does not cover shipping costs of defective or replacement parts.</p>
            <p>In no case is Excell Feeders liable to purchaser or any third party for loss of profits; or any incidental, indirect, consequential, special, exemplary, or punitive damages.</p>
          </div>
        </div>
      </section><!-- /Description Section -->
      <app-footer></app-footer>
    </div><!-- /Page Wrapper -->
  