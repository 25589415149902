import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from "rxjs";
import { DryService } from "../services/dry.service";
import { Dry } from "../models/dry";

@Component({
  selector: 'app-dry',
  templateUrl: './dry.component.html',
  styleUrls: ['./dry.component.css']
})
export class DryComponent implements OnInit {
  subscription: Subscription;
  drys: Dry[];

  constructor(private dryService: DryService) {
    //Subscribe to the service
    this.subscription = this.dryService.getItems().subscribe(drys => {
      this.drys = drys;
    })
   }

  ngOnDestroy(): void {
    //Unsubscribe from the service on destroy
    this.subscription.unsubscribe();
  }
  
  ngOnInit(): void {
  }

}