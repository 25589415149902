<!-- Home Static -->
<div class="home-static dark-overlay" style="background:url(assets/images/home-bg.jpg) no-repeat fixed;">
	<div class="header-inner">
		<div class="header-table">
			<div class="col-md-offset-2 col-md-8">
				<h2>Providing the best polymer feed solution</h2>
				<p>We are a chemical treatment equipment manufacturer that specializes in polymer feed systems.</p>
				<a routerLink="about" class="default-button white-bg">About us</a>
			</div>
		</div>
	</div>
</div><!-- /Home Static -->
<!-- Page Wrapper -->
<div class="page-wrapper">
	<!-- About Area -->
	<section class="about-area section-padding">
		<div class="container">
			<div class="row">
				<div class="col-md-5 col-sm-12 col-xs-12">
					<div class="about-img">
						<img class="img-responsive" src="assets/images/history-mlk.png" alt="" />
					</div>
				</div>
				<div class="col-md-7 col-sm-12 col-xs-12">
					<div class="history-content">
						<div class="section-title">
							<h2>Excell history</h2>
						</div>
						<p>Specializing in polymer feed solutions since 1993, Excell has advanced the technology behind polymer activation to provide unrivaled performance. Our systems have a user-friendly design that are both reliable and easy to maintain. We have spent the last 25 years perfecting our craft and pushing the envelope, to deliver eco-friendly designs that not only reduce the toxic impact on our environment but also require a fraction of the energy consumption needed to operate similar leading models.</p>
						<p>We are proud to have grown from simple designs to products that now offer a range of options and automation. We are most thankful for our loyal customers, past, present, and future, whom it has been a pleasure to serve. It is for you that we continually strive to be the best. Thank you.</p>
					</div>
				</div>
			</div>
		</div>
	</section><!-- /About Area -->
	<!-- Services Area -->
	<section class="services-area section-padding gray-bg white-overlay">
		<div class="container">
			<div class="row">
				<div class="section-title">
					<h2>Excell services</h2>
				</div>
			</div>
			<div class="row">
				<div class="col-md-4 col-sm-4 col-xs-12">
					<div class="single-service text-center">
						<div class="single-service-bod">
							<span class="pe-7s-tools"></span>
							<h3>Refurbish</h3>
							<p>If after a few years in the field you would like your Excell unit to be spruced up, we will restore it to working order for a nominal fee. Parts that are replaced will include the standard manufacturer’s warranty.</p>
						</div>
					</div>
				</div>
				<div class="col-md-4 col-sm-4 col-xs-12">
					<div class="single-service text-center">
						<div class="single-service-bod">
							<span class="pe-7s-edit"></span>
							<h3>Custom Equipment</h3>
							<p>Products at Excell are not limited only to Polymer Feed Equipment. We also have the expertise to manufacture systems that feed acid, clay, lime, permanganate, and a wide variety of other chemicals.</p>
						</div>
					</div>
				</div>
				<div class="col-md-4 col-sm-4 col-xs-12">
					<div class="single-service text-center">
						<div class="single-service-bod">
							<span class="pe-7s-plugin"></span>
							<h3>UL Panel Shop</h3>
							<p>Excell is recognized as a certified UL508A panel shop. Certification allows panel shop to independently design and produce UL ICP and apply a UL label. Each label certifies adherence to a known safety standard and is traceable to the panel shop that produced it.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section><!-- /Services Area -->
	<app-footer></app-footer>
</div>
<!-- /Page Wrapper -->
