<!-- Header Area -->
<header id="sticky-header">
	<div class="navbar navbar-default" role="navigation">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class="logo">
						<a class="navbar-brand white-logo" href="index.html"><img src="assets/images/new-logo-white.png" alt="" /></a>
						<a class="navbar-brand black-logo" href="index.html"><img src="assets/images/new-logo-black.png" alt="" /></a>
						<!-- <div class="navbar-search-mob">
						<a class="mk-search-trigger mk-fullscreen-trigger" id="search-button" href="#"><span class="pe-7s-search"></span></a>
					</div> -->
					<div class="mobile-menu"></div>
				</div>
				<div class="menu-area">
					<div class="menu-container">
						<nav class="menu">
							<ul>
								<li><a routerLink="">Home</a></li>
								<li><a routerLink="liquid">Liquid Systems</a></li>
								<li><a routerLink="dry">Dry Systems</a></li>
								<li><a routerLink="icp">Control Panels</a></li>
								<li><a routerLink="about">About Us</a></li>
								<li><a routerLink="contact">Contact</a></li>
								<!-- Desktop Screen Search Trigger -->
								<!-- <li id="navbar-search-bar">
								<a class="mk-search-trigger mk-fullscreen-trigger" id="search-button2" href="#"><span class="pe-7s-search"></span></a>
							</li> -->
						</ul>
					</nav>
				</div>
			</div>
		</div>
	</div>
</div>
</div>
<!-- /.Navigation -->
<!-- Search Bar -->
<div class="mk-fullscreen-search-overlay" id="mk-search-overlay">
	<a href="#" class="mk-fullscreen-close" id="mk-fullscreen-close-button"><i class="pe-7s-close"></i></a>
	<div id="mk-fullscreen-search-wrapper">
		<form method="get" id="mk-fullscreen-searchform" action="#">
			<input type="text" value="" placeholder="Search" id="mk-fullscreen-search-input" required>
			<i class="pe-7s-search fullscreen-search-icon"><input value="" type="submit"></i>
		</form>
	</div>
</div>
</header><!-- /Header Area -->
