<!-- Footer Area -->
<footer class="site-footer footer-v2">
    <div class="container">
      <div class="row">
        <div class="site-bottom">
          <div class="col-md-4 col-sm-6 site-bottom-1">
            <aside class="widget">
              <h3 class="widget-title">About US</h3>
              <ul class="menu">
                <li><a routerLink="/privacy">Privacy Policy</a></li>
                <li><a routerLink="/terms">Terms of Service</a></li>
                <li><a routerLink="/contact">Contact</a></li>
              </ul>
            </aside>
          </div>
          <div class="col-md-4 col-sm-6 site-bottom-2">
            <aside class="widget">
              <h3 class="widget-title">Systems</h3>
              <ul class="menu">
                <li><a routerLink="/liquid">Liquid Systems</a></li>
                <li><a routerLink="/dry">Dry Systems</a></li>
                <li><a routerLink="/icp">Industrial Control Panels</a></li>
              </ul>
            </aside>
          </div>
          <div class="col-md-4 col-sm-12 site-bottom-3">
            <aside class="widget widget-nav">
              <h3 class="widget-title">Contact</h3>
              <p>111 Import Road<br/>
                Pittston, PA 18640<br/>
                Tel: 732.828.8655<br/>
                Email: <a href="mailto:info@excellfeeders.com">info@excellfeeders.com</a>
              </p>
  
            </aside>
          </div>
          <!-- <div class="col-md-3 col-sm-6 site-bottom-4">
            <aside class="widget widget-text">
              <h3 class="widget-title">Newsletter</h3>
              <p>Subscribe to our newsletter to receive news, updates, free stuff and new releases by email. We don't do spam.</p>
              <form class="form-newsletter-ft" action="#" method="get">
                <input name="email" placeholder="Enter your email" required="" type="email">
                <input value="Go" type="submit">
              </form>
            </aside>
          </div> -->
        </div>
        <!-- .site-bottom -->
        <div class="bottom-footer">
          <div class="col-md-6 col-sm-4 pull-right">
            <div class="socials">
              <ul>
                <li><a href="mailto:info@excellfeeders.com"><i class="fa fa-envelope"></i></a></li>
                <li><a href="https://www.linkedin.com/company/excell-feeders-inc-/"><i class="fa fa-linkedin"></i></a></li>
                <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                <li><a href="#"><i class="fa fa-youtube"></i></a></li>
              </ul>
            </div>
          </div>
          <div class="col-md-6 col-sm-8">
            <div class="copyright">
              <p>With <i class="fa fa-heart"></i> by Alfredo Morales - &copy; {{currentDate | date:'yyyy'}} All Rights Reserved Excell Feeders, Inc.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- .row -->
    </div>
  </footer><!-- /Footer Area -->
  