<!-- Custom Page Header -->
<div class="custom-header fill dark-overlay" style="background:url(assets/images/contact-bg.jpg) no-repeat fixed;">
    <div class="header-inner">
      <div class="header-table">
        <h2>Contact Us</h2>
        <p><a class="brdcrm-home" routerLink="">Home</a> &mdash; Contact</p>
      </div>
    </div>
  </div><!-- /Custom Page Header -->
  <div class="page-wrapper">
    <section class="section-padding">
      <div class="container">
        <div class="row">
          <div class="section-title">
            <h2>Tell us about your project</h2>
            <p>Help us design your ideal polymer feed solution</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-ms-12 col-xs-12">
            <div class="single-contact-bar">
              <i class="pe-7s-map-marker"></i>
              <h4>Contact Address</h4>
              <p>111 Import Road Pittston, PA 18640</p>
            </div>
          </div>
          <div class="col-md-4 col-ms-12 col-xs-12">
            <div class="single-contact-bar">
              <i class="pe-7s-mail"></i>
              <h4>Get in touch</h4>
              <p><a href="mailto:sales@excellfeeders.com">sales@excellfeeders.com</a></p>
            </div>
          </div>
          <div class="col-md-4 col-ms-12 col-xs-12">
            <div class="single-contact-bar">
              <i class="pe-7s-paper-plane"></i>
              <h4>Call us</h4>
              <p>Call us at 732.828.8655</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!--Contact and map-->
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="page-wrapper">
          <div class="contact-area mb-40">
            <div class="contact">
              <!-- <form name="sentMessage" id="contactForm" novalidate (ngSubmit)="processForm()"> -->
              <form name="contactForm" id="contactForm" novalidate (ngSubmit)="onSubmit()">
                <div class="floating-label-form-group input-controls control-group">
                  <input type="text" placeholder="Name" id="name" name="name" required data-validation-required-message="Please enter your name." [(ngModel)]="contact.name" />
                  <p class="help-block text-danger"></p>
                </div>
                <div class="floating-label-form-group input-controls control-group">
                  <input type="email" placeholder="Email" id="email" name="email" required data-validation-required-message="You must enter your email address." [(ngModel)]="contact.email" />
                  <p class="help-block text-danger"></p>
                </div>
                <div class="floating-label-form-group input-controls control-group">
                  <input type="tel" placeholder="Phone" id="phone" name="phone" required data-validation-required-message="Insert your phone number." [(ngModel)]="contact.phone" />
                  <p class="help-block text-danger"></p>
                </div>
                <div class="floating-label-form-group input-controls control-group">
                  <textarea rows="10" placeholder="Message" id="message" name="message" required data-validation-required-message="Please leave a message." [(ngModel)]="contact.message" ></textarea>
                  <p class="help-block text-danger"></p>
                </div>
                <div id="success" *ngIf="success">
                  <div class='alert alert-success'>
                    <strong>Your message has been sent. </strong>
                  </div>
                </div>
                <button type="submit" class="default-button mt-10">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <!-- google map -->
        <div class="contact-map">
          <agm-map 
            [latitude]="lat" 
            [longitude]="lng" 
            [zoom]="zoom" 
            [disableDefaultUI]=true 
            [scrollwheel]=false 
            [styles]="styles"
          > 
            <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
          </agm-map>
        </div><!-- /google map -->
      </div>
    </div>
  </div><!--/Contact and map-->
  <app-footer></app-footer>
  