import { Component, OnInit, ViewChild } from '@angular/core';
import { ContactService } from "../services/contact.service";
import { Contact } from "../models/contact";


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  lat: number = 41.318160;
  lng: number = -75.729760;
  zoom: number = 15;
  styles: [ ];

  contact: Contact = {
    name: '',
    email: '',
    phone: '',
    message: ''
  }

  success: boolean;

  constructor(private contactService: ContactService) { }

  ngOnInit(): void {
    
  }

  onSubmit(){
    if(this.contact.email!= ''){
      this.contactService.addContact(this.contact);
      this.success = true;
      this.contact.name = '';
      this.contact.email = '';
      this.contact.phone = '';
      this.contact.message = '';
    }
  }

}
