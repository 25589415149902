<!-- Custom Page Header -->
<div class="custom-header fill dark-overlay" style="background:url(assets/images/liquid-bg.jpg) no-repeat fixed;">
    <div class="header-inner">
      <div class="header-table">
        <h2>Liquid Systems</h2>
        <p><a class="brdcrm-home" routerLink="">Home</a> &mdash; Liquid Systems</p>
      </div>
    </div>
  </div><!-- /Custom Page Header -->
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <!-- Description Section -->
    <section class="section-padding">
      <div class="container">
        <div class="row">
          <div class="section-title">
            <h2>Activation Apparatus</h2>
            <p>Patented Design</p>
          </div>
        </div>
        <div class="liquid-content">
          <div class="row">
            <div class="col-md-5 col-sm-12">
              <p>Our Polymer Activation Apparatus provides the high energy needed to activate all types of liquid polymers.</p>
              <p>The apparatus activates the polymer through a patented motor-less activation system. It injects the polymer into the dilution water stream and immediately makes the solution flow through a low pressure, high energy, activation nozzle.</p>
              <p>The apparatus operates like a low-pressure homogenizer. The polymer is activated as it passes through the annular nozzle orifice. The pressure drop across the nozzle is only 10 psi. This accelerates the solution to a velocity of about 40 ft./sec. The very high turbulence generated at the orifice activates the polymer. The polymer is exposed to this high activation energy only once, as it passes through the orifice.</p>
              <p>The orifice opening self-compensates for solution flow changes. This assures that the polymer activation energy level remains adequate for the flow range of the feeder.</p>
              <p>The activation apparatus components are made of the following thermoplastics: PVC, Teflon and polypropylene. The spring is made of PVDF coated stainless steel. The entire assembly is assembled with stainless steel rods, and BUNA-N O-rings. The assembly can be easily disassembled and reassembled for quick repairs.</p>
              <p>The injection valve has a PVDF body, a Viton seat and a Hastelloy spring. It is externally mounted for easy access.</p>
            </div>
            <div class="col-md-7 col-sm-12">
              <img src="assets/images/activation-apparatus.png">
            </div>
          </div>
        </div>
      </div>
    </section><!-- /Description Section -->
    <!-- Sizing Section -->
    <section class="sizing-area section-padding">
      <div class="container">
        <div class="row">
          <div class="section-title">
            <h2>Flow Rates</h2>
            <p></p>
          </div>
        </div>
        <div class="row">
          <div *ngFor="let system of liquids">
            <h4>{{system.description}}</h4>
            <a href="{{system.brochure}}">Download Brochure</a>
            <div class="container mt-60">
              <div class="row">
                <div class="col-md-2">
                  <strong>Model Number</strong>
                </div>
                <div class="col-md-5">
                  <strong>Polymer Rate</strong>
                </div>
                <div class="col-md-5">
                  <strong>Dilution Rate</strong>
                </div>
              </div>
              <hr>
              <div *ngFor="let model of system.models">
                <div class="row">
                  <div class="col-md-2">
                    <p>{{model.modelNumber}}</p>
                  </div>
                  <div class="col-md-5">
                    <p>{{model.polymerRate}}</p>
                  </div>
                  <div class="col-md-5">
                    <p>{{model.dilutionRate}}</p>
                  </div>
                </div>
                <hr>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section><!-- /Sizing Section -->
    <!-- Options Section -->
    <section class="faqs-area section-padding">
      <div class="container">
        <div class="row">
          <div class="section-title">
            <h2>Features & Options</h2>
            <!-- <p>Customizable to Meet your Needs</p> -->
          </div>
        </div>
        <div class="row">
          <h4>Standard Features</h4>
          <ul class="features-list">
            <li>An external polymer injection valve is accessible without disturbing the mixing apparatus. The valve spring is made of Hastelloy-C to minimize the possibility of stress corrosion cracking.</li>
            <li>A solenoid operated valve turns the water flow on - off.</li>
            <li>A switch monitors dilution water flow.  It turns off the pump whenever the flow is interrupted. A light provides local indication that water flow has been interrupted.</li>
            <li>An H-O-A switch allows local or remote Start-Stop control. The remote run switch is to be a dry contact. The feeder provides the 120 VAC to power the circuit.</li>
            <li>A digital display indicates pump speed.</li>
            <li>Pump speed is controlled manually or by a remote 4-20mA input.</li>
            <li>The sight glass is self-cleaning.  The patented assembly allows the operator to see how the feeder is operating.</li>
            <li>Stainless steel frame. The frame is floor mounted.</li>
          </ul>
          <h4>Available Options</h4>
          <ul class="features-list">
            <li>A one-gallon blending-aging tank improves polymer effectiveness.</li>
            <li>A polymer strainer and a pump calibration cylinder, with isolation valves.</li>
            <li>Pump cycle timer to allow pump feed rates below 0.3 GPD.</li>
            <li>Timer to allow a flush cycle.</li>
            <li>Pump speed controlled by the dilution water flow to maintain solution concentration.</li>
            <li>Voltages other than 120 VAC are available.</li>
            <li>PLC controlled.</li>
            <li>UL508A control panel.</li>
            <li>Custom designed to meet your needs.</li>
          </ul>
        </div>
      </div>
    </section><!-- /Options Section -->
    <!-- Dimensions Section -->
    <section class="sizing-area section-padding">
      <div class="container">
        <div class="row">
          <div class="section-title">
            <h2>Dimensions</h2>
            <p>General Arrangements</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <h4>Peristaltic Pump</h4>
            <h5>Configuration #1</h5>
            <img src="assets/images/6PSA2-300-S.png">
          </div>
          <div class="col-md-6">
            <h4>Peristaltic Pump</h4>
            <h5>Configuration #2</h5>
            <img src="assets/images/6BPS-300-S.png">
          </div>
        </div>
        <div class="row">
          <small>* May not be drawn to scale</small>
        </div>
        <div class="row  mt-60">
          <div class="col-md-6">
            <h4>Progressive Cavity Pump</h4>
            <h5>Configuration #3</h5>
            <img src="assets/images/6PC-300-S.png">
          </div>
        </div>
        <div class="row">
          <small>* May not be drawn to scale</small>
        </div>
      </div>
    </section><!-- /Dimensions Section -->
    <app-footer></app-footer>
  </div><!-- /Page Wrapper -->
  